.login {
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100%;
}

.login > div > img { 
    object-fit: contain;
    height: 200px;
}

.title {
    text-align: center;
}

.login > button {
    width: 300px;
    background-color: #3ea4fb;
    color: #eff2f5;
    font-weight: 800;
}

.logon > button:hover {
    background-color: gainsboro;
    color: #3ea4fb;
}