* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.home, .about, .history {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}