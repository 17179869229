.all_sections {
    display: flex;
    justify-content: center;
    align-content: center;
}

@media (max-width: 900px) {
    .all_sections {
        /* display: flex; */
        /* flex-direction: column; */
        flex-flow: column;
        align-items: center;
    }

    .all_sections > .calendar {
        margin: 1rem auto;
        min-width: 300px;
        max-width: 350px;
    }
    
    .all_sections > .subjects_section {
        margin: 0.5rem auto 1rem auto;
        max-height: 450px;
        min-width: 300px;
        max-width: 350px;
    }

    .all_sections > .progress {
        margin: 1rem auto 2rem auto;
        max-height: 450px;
        min-width: 300px;
        max-width: 350px;
    }

    /* .calendar {
        margin: 100px auto;
        min-width: 400px;
        max-width: 450px;
    }

    .subjects_section {
        min-width: 400px;
        max-width: 600px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .progress {
        min-width: 400px;
        max-width: 600px;
    } */

    /* .submit_button {
        visibility: visible;
        position: absolute;
    }     */
}

.calendar {
    /* z-index: -100; */
    font-family: sans-serif;
    /* font-size: 14px; */
    /* border: 2px solid black; */
    box-shadow: 0 0 2px 2px gray;
    border-radius: 0.5rem;
    margin: 0 0rem;
    background-color: white;
    max-height: 750px;
}

.calendar_title {
    margin: 10px auto -10px auto;
    font-size: 28px;
    text-align: center;
    font-weight: 800;
}
  
.calendar_header {
    margin: 0 0 3em;
}
  
.calendar_object {
    margin: 0 auto;
    max-width: 900px;
}

/* Customizes the first colomn of table */
.fc-axis.fc-time.fc-widget-content > span {
    color: black;
    font-size: 0.75rem;
    font-weight: 800;
    margin: 0 auto;  
    text-align: center;
} 

.subjects_section {
    background-color: white;
    /* border: 2px solid black; */
    box-shadow: 0 0 2px 2px gray;
    border-radius: 0.5rem;
    color: black;
    width: 20%;
    margin: 0 0.75rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* height: 700px; */
    max-height: 750px;

}

.create_form {
    width: 80%;
    text-align: center;
    padding: 0.5rem;
    border: 2px solid black;
    border-radius: 0.5rem;
}

.subjects_title {
    margin: 10px auto;
    font-size: 28px;
    text-align: center;
    font-weight: 800;
}

.subjects_container {
    display: flex;
    flex-direction: column;
    margin: 10px auto;
    width: 75%;
    justify-content: center;
    align-items: center;
    justify-items: center;
    text-align: center;
    overflow-x: hidden;
    overflow-y: auto;
}

.subject_buttons {
    border: 2px solid black;
    border-radius: 5px;
    width: 100%;
    margin: 10px auto;
    padding: 5px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    text-align: center;
    background-color: #317fdd;
    color: white;
}

.progress {
    width: 20%;
    /* border: 2px solid black; */
    /* min-height: 650px; */
    box-shadow: 0 0 2px 2px gray;
    margin: 0 0.75rem;
    background-color: white;
    border-radius: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 750px;
}

.submit_button {
    /* visibility: 'hidden';
    position: 'absolute'; */
    margin: .5rem auto 0 auto;
    padding: 0.25rem 0.5rem;
    background-color: #317fdd;
    color: white;
    font-family: sans-serif;
    border: 2px solid black;
    border-radius: 0.5rem;
}

.delete_button {
    padding: 5px;
    border-radius: 5px;
}

.delete_button:hover {
    background-color: red;
    cursor: pointer;
}

