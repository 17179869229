/* Navbar  */
.navbar_menu {
    background-color: #2867B2;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 950px) {
    .navbar_name {
        visibility: hidden;
    }
}

/* Navbar hamburger/close icon  */
.navbar_menu_icon {
    margin-left: 2rem;
    font-size: 2rem;
    background: none; 
    color: white;
}

/* Sidebar (hidden)  */
.nav_menu {
    background-color: #2867B2;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 100;
}

/* Moves sidebar out  */
.nav_menu.active {
    left: 0;
    transition: 350ms;
}

/* Navbar page items  */
.nav_text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

/* Navbar page links  */
.nav_text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

/* Hover effect for navbar page links  */
.nav_text a:hover {
    color: #2867B2;
    background-color: whitesmoke;
}

/* List of navbar page items  */
.nav_menu_items {
    width: 100%;
}

/* Sidebar header section */
.navbar_toggle {
    background-color: #2867B2;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* Navbar page links titles  */
span {
    margin-left: 16px;
}

/* Navbar logo and title  */
.navbar_title_section {
    align-items: center;
    color: white;
    display: flex;
    justify-content: center;
}

/* Navbar school logo  */
.navbar_logo {
    margin-right: 15px;
}

/* Navbar learner title  */
.navbar_title {
    font-weight: 800;
}

/* Navbar user section  */
.navbar_user_section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100px;
}

/* Navbar display name  */
.navbar_name {
    color: white;
    font-size: 16px;
    margin-right: 10px;
}

/* Navbar account avatar  */
.navbar_avatar {
    margin-right: 10px;
}